import * as React from "react"
import { Link } from "gatsby"
import './NotFound.scss';
import {Img} from 'react-image'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import img_1 from '../../images/404/7672380.jpg';

const NotFound = () => {
  const {t} = useTranslation()
  return (
    <main id="sbcom-404" className="pageStyles">
      <h1 className="headingStyles">{t('sbcom_404_text1')}</h1>
      <h2 className="headingStyles2">{t('sbcom_404_text2')}</h2>
      <Img className="imgStyles" src={img_1}></Img>
      <h3 className="headingStyles3">{t('sbcom_404_text3')}</h3>
      <p className="paragraphStyles">
        {t('sbcom_404_text4')}<br/>
        {t('sbcom_404_text5')}<br/>
      </p>
      <Link className="btn btn-primary" to="/">{t('sbcom_404_text6')}</Link>
    </main>
  )
}

export default NotFound

export const Head = () => <title>Not found</title>
