
import React from 'react';
//import HeaderPage from '../components/HeaderPage/HeaderPage'
import Footer from '../components/Footer/Footer'
import NotFound from '../components/NotFoundPage/NotFound'

import SaltybridgeLoader from '../components/sb/SaltybridgeLoader'
import SaltybridgeTopMenu from '../components/sb/SaltybridgeTopMenu'
import SaltybridegSection11 from '../components/sb/SaltybridegSection11'

import GoToTopButton from '../components/GoToTop'

import { withTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby';
import { SEO } from "../components/SEO/SEO"

class NotFoundPage extends React.Component {
      constructor (props){
        super(props);
        this.topMenuRef = React.createRef()
        this.scrollTo = this.scrollTo.bind(this)
      }
      
      scrollTo() {
        if (!this.topMenuRef.current) return;
        this.topMenuRef.current.scrollIntoView({ behavior: "smooth", block:"center"});
      }
      
      render() {
          const t = this.props.t
          return (
            <div className="Main">
            <SaltybridgeLoader/>
        
            <div id="page">
            <SaltybridgeTopMenu onContactUsClick={this.scrollTo}/>
            {/*<HeaderPage name={t("sbcom_header_page_no_page_found")}/>*/}
            <NotFound/>
            <SaltybridegSection11  ref={this.topMenuRef}/>
            <Footer/>
            </div>

            <GoToTopButton/>
            </div>
        );
    }
}

export default withTranslation()(NotFoundPage);

export const Head = () => (
  <SEO />
)

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["translation"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;